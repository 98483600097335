import React from 'react';
import CandidateStatusService from '../Services/CandidateStatusService';
import ErrorBanner from '../components/ErrorBanner';
import StoreDetailsService from '../Services/StoreDetailsService';
import PageEnum from '../Util/PageEnum';
import QuestionService from '../Services/QuestionService';
import PhoneScreenService from '../Services/PhoneScreenService';
import { Row, Col } from 'react-bootstrap';
import RequistionService from '../Services/RequisitionService';
import RequisitionService from "../Services/RequisitionService";


class StillInterested extends React.Component{
    
    constructor(props) {
        super(props);

        this.state = {
            applicantId: window.sessionStorage.getItem('applicantID'),
            loading: true,
            jobDetailsMessage: '',
            stillInterestedQuestion: '',
            jobDescription: '',
            errorMessage: '',
            address: '',
            city: '',
            state: '',
            zip:'',
            reqnId: '',
            locationTypeCode: '',
            jrFlag: '',
            jrStrFlag: ''

        }
    }
    
    UNSAFE_componentWillMount() {
        CandidateStatusService.updatePhoneScreenStatus(14).then(this.updatePhoneScreenResponse);
        StoreDetailsService.getStoreDeatilsServiceByApplicantId(this.state.applicantId).then(this.updateStoreDetails);
    }

    updateStoreDetails = (response) => {
        if(response && response.data){
            this.setState(function(){
                return {
                    address: response.data.addressLine1 + (response.data.addressLine2 ? " " + response.data.addressLine2 + "" : ""),
                    city: response.data.cityName,
                    state: response.data.stateCode,
                    zip: response.data.zipcode,
                    locationTypeCode: response.data.locationTypeCode,
                    jrFlag: response.data.jrFlag,
                    jrStrFlag: response.data.jrStrFlag
                }
            }, function() {
                   QuestionService.getStillInterestedQuestion(this.state.applicantId).then(this.stringReplaceAndSetInterestedMessage);
            });
        }
    }

    stringReplaceAndSetInterestedMessage = (response) => {
        if(response && response.data){
            var replacement = response.data.descriptionText.replace("%2$s",this.state.address);
            replacement = replacement.replace("%3$s",this.state.city);
            replacement = replacement.replace("%4$s",this.state.state);
            replacement = replacement.replace("%5$s",this.state.zip);
            this.setState(function(){
                return {
                    jobDetailsMessage: replacement,
                    stillInterestedQuestion: response.data.questionText,
                    jobDescription: response.data.jobDescription
                }
            });
        }
    }

    updatePhoneScreenResponse = (response) => {
        if(response.data.rowsUpdated === 1) {
            this.setErrorMessageState("");          
        } else {
            this.setErrorMessageState("Error updating Phone Screen Status");
        }
    }

    setErrorMessageState = (message) => {
        this.setState(function(){
            return {
                loading: false,
                errorMessage: message
            }
        });
    }

    closeEventHandler = (e) => {
        this.setState(function() {
            return {errorMessage: ''}
        });
    }

    yesEventHandler = () =>  {
        PhoneScreenService.updateMinReqAndPhoneScreenStatus(this.state.applicantId, 12, 14).then(this.updatePhoneScreenResponse);    
        QuestionService.updatePhoneScreenQuestionFlag( this.state.applicantId, 11,"Y").then(this.updatePhoneScreenQuestionFlagOnComplete).catch(() => {
            this.setState(function() {
                return {errorMessage : 'Error processing data.  Please try again.'}
            });
        });
    }

    noEventHandler = () => {
        //Commented as part of Job Recommendation changes and moved to on complete
        // PhoneScreenService.updateMinReqAndPhoneScreenStatus(this.state.applicantId, 5, 5).then(this.updatePhoneScreenResponse);
        RequistionService.getRequisitionNumberByApplId(this.state.applicantId).then(this.getRequisitionNumberByApplIdOnComplete);
        QuestionService.updatePhoneScreenQuestionFlag( this.state.applicantId, 11,"N").then(this.noEventOnComplete).catch(() => {
        this.setState(function() {
                return {errorMessage : 'Error processing data.  Please try again.'}
            });
        });

    }

    updatePhoneScreenQuestionFlagOnComplete = (resp) => {
        if(resp && resp.data && resp.data.rowsUpdated === 1){
            window.sessionStorage.setItem('locationTypeCd', this.state.locationTypeCode);
            if ((this.state.locationTypeCode === "STR" ) || (this.state.locationTypeCode === "DC")) {
                this.props.history.push({pathname: PageEnum.MinimumAge.url});
            } else {
                //this.props.history.push({pathname: PageEnum.JobPreview.url});
                this.props.history.push({ pathname: PageEnum.Questions.url });
            }
        } else {
             this.setState(function() {
                return { errorMessage : 'Error processing data.  Please try again.'}
            });
        }
    }
    
    updateInterviewStatusOnComplete = (resp) => {
        if (!resp || !resp.data) {
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
    }

    getRequisitionNumberByApplIdOnComplete = (resp) => {
        if (!resp || !resp.data) {
            this.setState(function () {
                return { errorMessage: 'Error processing data.  Please try again.' }
            });
        }
        else{
            var reqNbr = resp.data.requisitionNumber;
            PhoneScreenService.updateInterviewStatus(this.state.applicantId,reqNbr,0).then(this.updateInterviewStatusOnComplete);
            this.setState(function () {
                return {
                    reqnId: reqNbr
                }
            });
        }
    }

   noEventOnComplete = (resp) => {

        if(resp && resp.data && resp.data.rowsUpdated === 1) {
            // Added for Job Recommendation STR , alternate req and msg 26. If location type is not STR, regular details will be displayed
            // console.log("locationTypeCode : " + this.state.locationTypeCode);
            // console.log("jrFlag: " + this.state.jrFlag);
            // console.log("jrStrFlag: " + this.state.jrStrFlag);


            if (this.state.locationTypeCode === 'STR'
                        && (this.state.jrFlag === 'Y' || (this.state.jrFlag === 'P' && this.state.jrStrFlag === 'Y'))) {

                RequisitionService.getAltReqByApplicantId(this.state.applicantId)
                    .then(response => {
                        let altReqs = response.data;
                        console.log("Count of alternate Reqs : ", altReqs.length);

                        if ( altReqs && altReqs.length > 0) {
                            this.props.history.push({pathname: PageEnum.AlternateRequisition.url, state : { altReqs: altReqs}});
                         } else {
                            //Phone screen status as 24 for No alternate available
                            CandidateStatusService.updatePhoneScreenStatus(24).then(this.updatePhoneScreenResponse);
                            this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=26"}); }

                    })
                    .catch(error => {
                        console.error("Error:", error);
                    });
             // This Else is for DC location type or when the JR flag is N
            } else { PhoneScreenService.updateMinReqAndPhoneScreenStatus(this.state.applicantId, 5, 5).then(this.updatePhoneScreenResponse);
                //Changes for Job Recommendation Was route 13 now route 26 (Same page different msg)
                // this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=13"});
                this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=26"});
            }

        } else {
             this.setState(function() {
                return { errorMessage : 'Error processing data.  Please try again.'}
            });
        }
    }
    
    
    render() {
        return(
            <div>
                {this.state.loading ? <Row><Col sm={12}>Loading...</Col></Row> : "" }
                <ErrorBanner submitErrorMessage={this.state.errorMessage} closeEventHandler={this.closeEventHandler} />
                <div>
                    <Row>
                        <Col sm={12} className= 'interestedInPositionMessage'>
                            <div dangerouslySetInnerHTML={{__html: this.state.jobDetailsMessage}}></div>

                        </Col>

                        {/*<Col xs={12} className= 'interestedInPositionMessage'>*/}
                        {/*    <div >{this.state.jobDescription}</div>*/}
                        {/*</Col>*/}

                        <Col sm={12}  className= 'stillInterestedQuestion'>
                            <div >{this.state.stillInterestedQuestion}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <button className='stillInterested button' type='submit' onClick={this.yesEventHandler}> Yes </button>
                            <button className='stillInterested button' type='submit'onClick={this.noEventHandler}> No </button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default StillInterested;