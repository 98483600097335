// Added component Job recommendation

import React from "react";
import QuestionService from "../Services/QuestionService";
import AlternateJobs from "./AlternateJobs";
import {Col, Row} from "react-bootstrap";
import CandidateStatusService from "../Services/CandidateStatusService";
import PageEnum from "../Util/PageEnum";
import RequisitionService from "../Services/RequisitionService";


class AlternateRequisition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            applicantId: window.sessionStorage.getItem('applicantID'),
            altReqs: [],
            loaded: false

        }
    }


    componentDidMount() {
        var applId = window.sessionStorage.getItem('applicantID');

        this.setState(() => {

            return {
                altReqs: this.props.location.state.hasOwnProperty("altReqs") ? this.props.location.state.altReqs : [],
                loaded: true
            }
        });

    }

    noEventHandler = () => {
        //Added to update phone screen status to Alternate declined
        CandidateStatusService.updatePhoneScreenStatus(23).then(this.updatePhoneScreenResponse);
        this.props.history.push({pathname: PageEnum.Message.url, search: "?msgId=27"});
        RequisitionService.updateAltReqNotInterested(this.state.applicantId).then(this.updateAltReqResponse);
    }

    updatePhoneScreenResponse = (response) => {
        if(response.data.rowsUpdated === 1) {
            this.setErrorMessageState("");
        } else {
            this.setErrorMessageState("Error updating Phone Screen Status");
        }
    }

    updateAltReqResponse = (response) => {
        if(response.data.rowsUpdated === 1) {
            this.setErrorMessageState("");
        } else {
            this.setErrorMessageState("Error updating Alternate Req Status");
        }
    }

    render() {
        return (
            <div className="alternateReq" >
                <div className="d-grid col-sm-12">
                    <h2><strong>What About These?</strong></h2>
                </div>
                <div className="col-sm-12"> We found some other positions that might work for you. Let us know if you are
                    interested or
                    not! <br/> <br/>
                </div>
                <div>
                    {console.log("altreq in alternate req.js: ", this.state.altReqs)}
                    {this.state.altReqs.map((req, index) => <AlternateJobs key={index} requisition={req} history={this.props.history} />)}
                </div>
                <div className="buttonSpacing d-flex" >
                    {/*<Col xs={6} sm={4} >*/}
                        <button className="button tertiary flex-fill" type="button" onClick={this.noEventHandler}>Not Interested</button>
                    {/*</Col>*/}
                </div>
            </div>
        )
    }
}

export default AlternateRequisition;