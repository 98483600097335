import axios from 'axios';
import redirectService from "./RedirectService";


var PhoneScreenService = {
    updateInterviewStatus: function (applId, reqNbr, interviewStatusCode) {
        return axios.put('/api/phoneScreen/interviewStatus/' + reqNbr + '/' + interviewStatusCode,'',{headers: { "Content-type":"application/json"}}).then(function (response) {
            return response;
        }).catch(e => redirectService.redirect(e));
    },
    updateMinReqAndPhoneScreenStatus: function (applicantId, minReqStatCd, phoneScreenStatCd) {
        return axios.put('/api/phoneScreen/minRequirementAndPhoneScreenStatus/' + minReqStatCd + '/' + phoneScreenStatCd,'',{headers: { "Content-type":"application/json"}}).then(function (response) {
            return response;
        }).catch(e => redirectService.redirect(e));
    }
}
export default PhoneScreenService;