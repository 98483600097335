import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Button, Drawer, Modal} from "antd";
import RequisitionService from "../Services/RequisitionService";
import PageEnum from "../Util/PageEnum";


const AlternateJobs = (props) => {


    // Status to set drawer open or close
    const [isOpen, setIsOpen] = useState(false);
    const showDrawer = () => setIsOpen(true);
    const closeDrawer = () => setIsOpen(false);

    // Status to set modal open or close
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const showModal = () => setIsModalOpen(true);
    // const closeModal = () => setIsModalOpen(false);
    const [modalOpen, setModalOpen] = useState(false);

    const showModal = () => {
        setModalOpen(true);
    };
    const handleOk = () => {
        let applicantId = window.sessionStorage.getItem('applicantID');
        setModalOpen(false);
        setIsOpen(false);

        // console.log("UI Move Var applicant Id " + applicantId);
        // console.log("UI Move Var origRequisitionId " + props.requisition.origRequisitionId);
        // console.log("UI Move Var reqnId " + props.requisition.requisitionId);
        // console.log("UI Move Var strNbr " + props.requisition.strNbr);

        RequisitionService.moveCandidateToAltReq(applicantId,props.requisition.origRequisitionId,props.requisition.requisitionId, props.requisition.strNbr)
            .catch(error => {
                console.error('An error occurred while moving candidate:', error);
                return Promise.reject(error);
            });
        props.history.push({pathname: PageEnum.MinimumAge.url});

    };
    const handleCancel = () => {
        setModalOpen(false);
    };

        return (
            <div>

                <h5><strong>{props.requisition.jobTtlDesc}</strong></h5>
                <h6>{"Employment Type: " + props.requisition.empltTyp}</h6>
                {props.requisition.jobTtlCd.trim() === 'SALES' && <h6>{"Department: " + props.requisition.deptDesc}</h6>}
                <Row>
                    <Col sm={12} className='bottomButtonBuffer d-flex'>
                        <>
                            {/*<Button type='primary' onClick={showDrawer}> See Details </Button>*/}
                            <button className='seedteails button flex-fill' type='submit' onClick={showDrawer}> See
                                Details
                            </button>
                            <Drawer title={props.requisition.jobTtlDesc.trim()} getContainer={false} onClose={closeDrawer}
                                    open={isOpen}>
                                <h6>{"Employment Type: " + props.requisition.empltTyp}</h6>
                                {props.requisition.jobTtlCd.trim() === 'SALES' &&
                                    <h6>{"Department: " + props.requisition.deptDesc}</h6>}
                                <h6>{"Job Description: " } <br/> {props.requisition.jobDetailDesc}</h6>
                                <Col sm={12} className='bottomButtonBuffer d-flex'>
                                    <button className='canceldteails button flex-fill' type="submit"
                                            onClick={closeDrawer}> Close
                                    </button>
                                    <>
                                    <button className='iminterested button flex-fill' type="submit" onClick={showModal}> I'm Interested
                                    </button>

                                        <Modal title="Begin Screening" open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
                                            <h5> You will now begin the screening<br/>
                                                process for the position {props.requisition.jobTtlDesc.trim()}.
                                            </h5>
                                        </Modal>
                                    </>
                                </Col>
                            </Drawer>

                        </>
                        <>
                        <button className='interested button flex-fill' type='submit' onClick={showModal}> I'm Interested</button>
                            <Modal title="Begin Screening" open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
                                <h5> You will now begin the screening<br/>
                                    process for the position {props.requisition.jobTtlDesc.trim()}.
                                </h5>
                            </Modal>
                        </>
                    </Col>
                </Row>
            </div>
        );


}

export default AlternateJobs;